export enum FeatureFlag {
  DEVELOPER = 'developer',
  BACKGROUND_BLURRING = 'backgroundBlurring',
  CUSTOM_BACKGROUND = 'custombackground',
  BOOMCARDS_SITESHARE = 'boomcardsSiteShare',
  BOOMCARDS_IFRAME = 'boomcardsIframe',
  INTEGRATED_LIBRARY = 'integratedLibrary',
  NEW_LIBRARY = 'newLibrary',
  NEW_GAMES_DRAWER = 'newGamesDrawer',
  SECONDARY_CAMERA_ALERT = 'secondarycameraalert',
  NEW_LIBRARY_ACTIVITIES = 'newLibraryActivities',
  NEW_LIBRARY_MY_MATERIALS = 'newLibraryMyMaterials',
  INACTIVITY_AUTOLOGOUT_BYPASS = 'inactivityAutologoutBypass',
}
